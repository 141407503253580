import * as React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Footer from "../components/footer";
import Layout from "../components/layout";
import WorkItem from '../components/work-item';
import workIcon3 from '../images/work/3.png';
import workIcon10 from '../images/work/10.png';
import workIcon5 from '../images/work/5.png';
import workIcon6 from '../images/work/6.png';
import workIcon7 from '../images/work/7.png';
import workIcon8 from '../images/work/8.png';
import workIcon9 from '../images/work/9.png';
import CTA from '../components/cta';

// markup
const WorkPage = () => {
  return (
    <Layout pageClass="work-page">
        <Helmet>
            <title>Our Portfolio | MatteCurve</title>
            <meta name="description" content="We have shared our recent work related to fintech products, metrics intelligence platforms for banks, etc. and we have developed 20+ web and mobile development projects for 10+ industries to improve, automate business workflow and productivity" />
            <meta name="keywords" content="Web Development, Mobile App Development, React, Node.js, Angular.js, Remote Developers" />
        </Helmet>
        <Header></Header>
        <main>
        <div className="hero-wrapper container-fluid">
            <h1 className="animate__animated animate__fadeInUp">Expert Solutions</h1>
            <br/>
            <p className="animate__animated animate__fadeInUp">
                We are solving incredibly complex problems for out clients. Here are some examples.
            </p>
            <br/>
            <div className="works-container">
                <div>
                    <WorkItem 
                        image={workIcon8} 
                        name="BlueTickMe"
                        url="https://www.bluetickme.com"
                        descriptionOne="BlueTickMe offers access to restaurants, bars, nightclubs, and events worldwide."
                        descriptionTwo="Our team worked for over six months to build the backend system of this platform. We used Node.js, MongoDB, and GCP as the tech stack. Secure access was implemented using fingerprint."
                    />
                    <WorkItem 
                        image={workIcon3} 
                        name="TheRemoteWork"
                        url="https://theremotework.co"
                        descriptionOne="TheRemoteWork is a growing remote work community. TheRemoteWork is the best destination to find and list remote jobs."
                        descriptionTwo="We have built this product using Laravel, Node.js, and MySQL and deployed it on the Linode server."
                    />
                    <WorkItem 
                        image={workIcon9} 
                        name="VideoFeedbacks"
                        url="https://videofeedbacks.com/"
                        descriptionOne="VideoFeedbacks is a platform to collect video feedbacks from your customers."
                        descriptionTwo="We have built this product using AngularJS, and Firebase and deployed it on the Linode server."
                    />
                    <WorkItem 
                        image={workIcon10} 
                        name="BlackGentry"
                        url=""
                        descriptionOne="BlackGentry is a dating app."
                        descriptionTwo="The app was having performance issues and we have worked with the client to fix Node.js performance issues on the product."
                    />
                    {/* <WorkItem 
                        image={workIcon5} 
                        name="TheSkyBuilders"
                        url=""
                        descriptionOne="TheSkyBuilders is a construction firms."
                        descriptionTwo="We have built their website using HTML, CSS and JavaScript."
                    />
                    <div className="w-100"></div>
                    <WorkItem 
                        image={workIcon6} 
                        name="Life On Plan"
                        url=""
                        descriptionOne="Life On Plan is a learning and jobs platform for students."
                        descriptionTwo="We have built this product using React and Node.js."
                    />
                    <div className="w-100"></div>
                    <WorkItem 
                        image={workIcon7} 
                        name="Zealo Tech People"
                        url=""
                        descriptionOne="Zealotech People are experts in providing Recruiting Offshore Services and Software Development Solutions for companies throughout the world from India."
                        descriptionTwo="We have built their website."
                    /> */}
                </div>
            </div>
        </div>
        <div className="topic-cta-section">
            <CTA text='Hire dedicated team to build your next project.'></CTA>
        </div>
        </main>
        <Footer></Footer>
    </Layout>
  )
}

export default WorkPage;
