import React, {useState} from "react";

export default function WorkItem({image, name, url, descriptionOne, descriptionTwo}) {
  const [imageContainerClass, setImageContainerClass] = useState("work-container-icon");

  return (
    <div className="row">
        <div className="col col-12 order-2 col-sm-12 col-md-6 animate__animated animate__fadeInUp">
            <div className="work-container">
                <h4>
                    {!!url &&
                        <u><a target='_blank' href={url}>{name}</a></u>
                    }
                    {!url &&
                        <span>{name}</span>
                    }
                </h4>
                <p>{descriptionOne}</p>
                <br />
                <p>{descriptionTwo}</p>
            </div>
        </div>
        <div className="col col-12 order-1 col-sm-12 col-md-6 animate__animated animate__fadeInUp">
            <div role="button" tabIndex="0" className={imageContainerClass} onFocus={() => setImageContainerClass("work-container-icon animate__animated animate__pulse")} onMouseOver={() => setImageContainerClass("work-container-icon animate__animated animate__pulse")} onMouseOut={() => setImageContainerClass("work-container-icon")} onBlur={() => setImageContainerClass("work-container-icon")} >
                <img src={image} alt={name} />
            </div>
        </div>
    </div>
  )
}